/* eslint-env browser */

import {throttle, debounce} from 'throttle-debounce';
import {customElement} from 'solid-element';
import {onCleanup, createEffect, createSignal, splitProps} from 'solid-js';
import {menu} from 'material-components-web';
import {Input, HelpText} from '../vf-field-input/index';
import cc from 'classcat';

import {VMultiAutocompleteField} from './multi.jsx';
import './index.scss';


const defaultProps = {
  'autofocus': undefined,
  'disabled': false,
  'error': undefined,
  'helpText': undefined,
  'id': undefined,
  'label': undefined,
  'leadingIcon': undefined,
  'maxlength': undefined,
  'minlength': undefined,
  'name': undefined,
  'placeholder': undefined,
  'required': false,
  'step': undefined,
  'trailingIcon': undefined,
  'type': 'text',
  'value': '',
  'valueLabel': '',
};


const VAutocompleteField = customElement('vf-field-autocomplete', defaultProps, (props, {element}) => {
  let menuEl;
  let mdcMenu;
  let wrapperEl;
  let inputEl;
  // let dataEl;

  const [state, setState] = createSignal({
    suggestions: [],
  });

  const [selected, setSelected] = createSignal(props.value);

  Object.defineProperty(element, 'renderRoot', {
    value: element,
  });

  createEffect(() => {
    mdcMenu = new menu.MDCMenu(menuEl);
    mdcMenu.listen('MDCMenu:selected', (event)=>{
      setSelection(event.detail.index);
      inputEl.focus();
    });

    inputEl = wrapperEl.querySelector('input[type=text]');
    // dataEl = wrapperEl.querySelector('input[type=hidden]');
  });

  onCleanup(() => {
    if(mdcMenu) {
      mdcMenu.destroy();
    }
  });

  const fetchSuggestions = async (value) => {
    const result = await fetch('', {
      method: 'OPTIONS',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-Request-Autocomplete': `field=${props.name}&query=${encodeURIComponent(value)}`,
      },
    });
    mdcMenu.open = true;
    mdcMenu.selectedIndex = 0;
    const data = await result.json();
    setState({'suggestions': data.suggestions});
  };

  const throttledFetch = throttle(200, fetchSuggestions);
  const debouncedFetch = debounce(500, fetchSuggestions);

  const autocompleteFetchSuggestions = () => {
    const value = inputEl.value;
    if (value.length < 5) {
      throttledFetch(value);
    } else {
      debouncedFetch(value);
    }
  };

  const setSelection = (selectedIndex)=> {
    const selectedValue = state().suggestions[selectedIndex].value;
    const selectedData = state().suggestions[selectedIndex].data.id;

    mdcMenu.open = false;
    setState({
      'suggestions': [],
    });

    setSelected(selectedData);
    setTimeout(() => {
      inputEl.value = selectedValue;
      // dataEl.value = selectedData;
    });

    // mdcMenu.list_.singleSelection = true;
    // mdcMenu.list_.selectedIndex = -1;
  };

  const onKeyDown = async (event) => {
    if (event.key == 'ArrowUp') {
      // todo scroll
      mdcMenu.selectedIndex = Math.max(0, mdcMenu.selectedIndex - 1);
      event.preventDefault();
    } else if (event.key == 'ArrowDown') {
      mdcMenu.selectedIndex = Math.min(mdcMenu.items.length, mdcMenu.selectedIndex + 1);
    } else if (event.key == 'Enter') {
      if (mdcMenu.open) {
        setSelection(mdcMenu.selectedIndex);
        event.preventDefault();
      }
    } else if (event.key == 'Escape') {
      mdcMenu.open = false;
      mdcMenu.list_.singleSelection = true;
      mdcMenu.list_.selectedIndex = -1;
    }
  };

  const onKeyUp = async (event) => {
    const ignoredKeys = [
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Enter',
      'Escape',
      'Tab',
    ];
    if (!ignoredKeys.includes(event.key)) {
      // dataEl.value = '';
      setSelected('');
      autocompleteFetchSuggestions();
    }
  };

  const onFocusOut= () => {
    setTimeout(() => {
      mdcMenu.open = false;
      if (!selected()) { // if (!dataEl.value) {
        inputEl.value='';
      }
    }, 100);
  };

  const onTrailingButtonClick = () => {
    if (!selected()) {
      autocompleteFetchSuggestions();
    } else {
      setSelected(undefined);
      inputEl.value='';
    }
  };

  const [inputProps, others] = splitProps(props, ['valueLabel', 'label', 'error']);

  const items = (suggestions) => {
    const result = [];
    for (const groupData of suggestions) {
      result.push(
          <li class="mdc-list-item mdc-list-item--with-one-line" role="menuitem" data-value={ groupData.data.id }>
            <span class="mdc-list-item__ripple"></span>
            <span class="mdc-list-item__text mdc-list-item__content">{groupData.value}</span>
          </li>,
      );
    }
    return result;
  };

  return (
    <div
      class={ cc({
        'vf-field__row': true,
        'no-value': !selected(),
      }) }
      ref={wrapperEl}
      onFocusOut={onFocusOut}
    >
      <Input
        autocomplete="off"
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        value={inputProps.valueLabel}
        error={inputProps.error}
        label={inputProps.label}
        trailingButton={() => props.disabled ? '' : selected() ? 'clear' : 'more_vert'}
        onTrailingButtonClick={onTrailingButtonClick}
        type="text"
        id={`${props.id}_control`}
        disabled={props.disabled}
      />
      <input
        name={others.name}
        type={others.type}
        value={selected()}
      />
      <div class="mdc-menu-surface--anchor">
        <div class="mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth" ref={menuEl}>
          <ul class="mdc-list" role="listbox" aria-label="Food picker listbox">
            { items(state().suggestions) }
          </ul>
        </div>
      </div>
      { props.helpText || props.error ? <HelpText {...props}/> : '' }
    </div>
  );
});

export {
  VAutocompleteField,
  VMultiAutocompleteField,
};
