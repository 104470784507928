/* eslint-env browser */

import {customElement} from 'solid-element';
import {createSignal, onCleanup} from 'solid-js';

const defaultProps = {
  'disabled': false,
  'error': undefined,
  'helpText': undefined,
  'id': undefined,
  'label': undefined,
  'leadingIcon': undefined,
  'name': undefined,
  'optgroups': undefined,
  'required': false,
  'trailingIcon': undefined,
  'value': undefined,
  'parent': undefined,
};


const VDependentSelectField = customElement('vf-field-select-dependent', defaultProps, (props, {element}) => {
  Object.defineProperty(element, 'renderRoot', {
    value: element,
  });

  const [value, setValue] = createSignal(props.value);
  const [optgroups, setOptgroups] = createSignal(props.optgroups);
  const parentForm = element.closest('form');
  const parentSelect = parentForm.querySelector('vf-field-select[name='+props.parent+'],vf-field-select-dependent[name='+props.parent+']');

  const fetchOptions = async (value) => {
    const result = await fetch('', {
      method: 'OPTIONS',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-Request-Select-Options': `field=${props.name}&query=${encodeURIComponent(value)}`,
      },
    });
    const response = await result.json();
    setValue(undefined);
    setOptgroups(response.data);
  };


  const onParentChange = (event) => {
    fetchOptions(event.detail.value);
  };

  parentSelect.addEventListener('change', onParentChange);

  onCleanup(() => {
    parentSelect.removeEventListener('change', onParentChange);
  });

  if(props.value) {
    setTimeout(async ()=> {
      if(parentSelect.value) {
        await fetchOptions(parentSelect.value);
        setValue(props.value);
        const event = new CustomEvent('change', {
          bubbles: true,
          detail: {value: props.value},
        });
        this.dispatchEvent(event);
      }
    }, 500)
  }

  return (
    <vf-field-select
      disabled={props.disabled}
      error={props.error}
      helpText={props.helpText}
      id={props.id}
      label={props.label}
      leadingIcon={props.leadingIcon}
      name={props.name}
      optgroups={optgroups()}
      required={props.required}
      trailingIcon={props.trailingIcon}
      value={value()}
    ></vf-field-select>
  );
});

export {
  VDependentSelectField,
};
